import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // 결연후원 페이지
        path: "/support/relationship",
        component: () => import("../pages/client/board/support/Support.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/join",
        component: () => import("../pages/client/join/main.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/login",
        component: () => import("../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/login/find-id/",
        component: () => import("../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/login/find-password/",
        component: () => import("../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/join/form",
        component: () => import("../pages/client/join/form.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/join/complete",
        component: () => import("../pages/client/join/complete.vue"),
        props: true,
    },
    {
        // 결연후원 페이지
        path: "/join/type",
        component: () => import("../pages/client/join/form.vue"),
        props: true,
    },
    {
        // 정기후원 페이지
        path: "/support/regular",
        component: () => import("../pages/client/board/support/Regular.vue"),
        props: true,
    },
    {
        // 일시후원 페이지
        path: "/support/temporary",
        component: () => import("../pages/client/board/support/Temporary.vue"),
        props: true,
    },
    {
        // 해외결제 페이지
        path: "/support/foreign",
        component: () => import("../pages/client/board/support/Foreign.vue"),
        props: true,
    },
    {
        // 기업후원 페이지
        path: "/support/corporation",
        component: () => import("../pages/client/board/support/Corporation.vue"),
        props: true,
    },
    {
        // 물품후원 페이지
        path: "/support/commodity",
        component: () => import("../pages/client/board/support/Commodity.vue"),
        props: true,
    },
    {
        // 후원하기 가이드
        path: "/support/guide",
        component: () => import("../pages/client/board/support/GuidePage.vue"),
        props: true,
    },
    {
        // 후원하기 유산기부
        path: "/support/legacy",
        component: () => import("../pages/client/board/support/LegacyPage.vue"),
        props: true,
    },

    {
        // 소식받기 페이지
        path: "/support/news",
        component: () => import("../pages/client/board/support/News.vue"),
        props: true,
    },
    {
        // 국내,국제 캠페인
        path: "/campaign/campaign",
        component: () => import("../pages/client/board/campaign/Campaign.vue"),
        props: true,
    },

    {
        // 국내,국제 캠페인 글쓰기
        path: "/campaign/campaign/write",
        component: () => import("../pages/client/board/campaign/Campaign.vue"),
        props: true,
    },
    // {
    //     // 국제 캠페인
    //     path: "/campaign/international",
    //     component: () => import("../pages/client/board/campaign/Campaign.vue"),
    //     props: true,
    // },
    {
        // 캠페인 그 후 
        path: "/campaign/after",
        component: () => import("../pages/client/board/campaign/After.vue"),
        props: true,
    },
    {
        // 비대면 봉사활동 (단체)
        path: "/campaign/group",
        component: () => import("../pages/client/board/campaign/Group.vue"),
        props: true,
    },
    {
        // 비대면 봉사활동 (단체)
        path: "/campaign/:_campaign",
        component: () => import("../pages/client/board/campaign/Group.vue"),
        props: true,
    },


    {
        // 법인소개 소개
        path: "/company/intro",
        component: () => import("../pages/client/sub/company/IntroPage.vue"),
        props: true,
    },
    {
        // 법인소개 인사말
        path: "/company/greeting",
        component: () => import("../pages/client/sub/company/GreetingPage.vue"),
        props: true,
    },
    {
        // 법인소개 ci
        path: "/company/ci",
        component: () => import("../pages/client/sub/company/CIPage.vue"),
        props: true,
    },
    {
        // 법인소개 연혁
        path: "/company/history",
        component: () => import("../pages/client/sub/company/HistoryPage.vue"),
        props: true,
    },
    {
        // 법인소개 조직도
        path: "/company/organization",
        component: () => import("../pages/client/sub/company/OrganizationPage.vue"),
        props: true,
    },
    {
        // 법인소개 투명한운영
        path: "/company/operation",
        component: () => import("../pages/client/sub/company/OperationPage.vue"),
        props: true,
    },
    {
        // 법인소개 투명한운영
        path: "/company/supporters",
        component: () => import("../pages/client/sub/company/SupportersPage.vue"),
        props: true,
    },
    {
        // 법인소개 투명한운영
        path: "/company/operation/:_board",
        component: () => import("../pages/client/sub/company/OperationPage.vue"),
        props: true,
    },
    {
        // 법인소개 오시는길
        path: "/company/location",
        component: () => import("../pages/client/sub/company/LocationPage.vue"),
        props: true,
    },
    {
        // 법인소개 나눔기업
        path: "/company/partner",
        component: () => import("../pages/client/sub/company/PartnerPage.vue"),
        props: true,
    },

    {
        // 국내사업 시설운영지원 보육/아동돌봄
        path: "/business-domestic/operate/care",
        component: () => import("../pages/client/sub/business-domestic/operate/CarePage.vue"),
        props: true,
    },
    {
        // 국내사업 시설운영지원 아동보호
        path: "/business-domestic/operate/child",
        component: () => import("../pages/client/sub/business-domestic/operate/ChildPage.vue"),
        props: true,
    },
    {
        // 국내사업 시설운영지원 중장년복지
        path: "/business-domestic/operate/middle",
        component: () => import("../pages/client/sub/business-domestic/operate/MiddlePage.vue"),
        props: true,
    },
    {
        // 국내사업 시설운영지원 장애인복지
        path: "/business-domestic/operate/disabled",
        component: () => import("../pages/client/sub/business-domestic/operate/Disabled.vue"),
        props: true,
    },
    {
        // 국내사업 시설운영지원 노인복지
        path: "/business-domestic/operate/senile",
        component: () => import("../pages/client/sub/business-domestic/operate/SenilePage.vue"),
        props: true,
    },
    {
        // 국내사업 시설운영지원 지역복지
        path: "/business-domestic/operate/country",
        component: () => import("../pages/client/sub/business-domestic/operate/CountryPage.vue"),
        props: true,
    },
    {
        // 국내사업 사회복지 전략기획사업
        path: "/business-domestic/welfare",
        component: () => import("../pages/client/sub/business-domestic/WelfarePage.vue"),
        props: true,
    },
    {
        // 국내사업 지역공동체지원
        path: "/business-domestic/community",
        component: () => import("../pages/client/sub/business-domestic/CommunityPage.vue"),
        props: true,
    },
    {
        // 국내사업 연구개발
        path: "/business-domestic/development",
        component: () => import("../pages/client/sub/business-domestic/DevelopmentPage.vue"),
        props: true,
    },
    {
        // 국내사업 교육사업
        path: "/business-domestic/education",
        component: () => import("../pages/client/sub/business-domestic/EducationPage.vue"),
        props: true,
    },
    {
        // 해외사업 해외아동결연
        path: "/business-overseas/child",
        component: () => import("../pages/client/sub/business-overseas/ChildPage.vue"),
        props: true,
    },
    {
        // 해외사업 지역개발
        path: "/business-overseas/country",
        component: () => import("../pages/client/sub/business-overseas/CountryPage.vue"),
        props: true,
    },
    {
        // 해외사업 교육지원
        path: "/business-overseas/education",
        component: () => import("../pages/client/sub/business-overseas/EducationPage.vue"),
        props: true,
    },
    {
        // 해외사업 긴급구호
        path: "/business-overseas/rescue",
        component: () => import("../pages/client/sub/business-overseas/RescuePage.vue"),
        props: true,
    },
    {
        // 소통과 참여
        path: "/community/board",
        component: () => import("../pages/client/board/community/Board.vue"),
        props: true,
    },
    {
        // 소통과 참여 - faq
        path: "/community/faqs",
        component: () => import("../pages/client/board/community/Faq.vue"),
        props: true,
    },
    {
        // 소통과 참여 - 소식지
        path: "/community/news",
        component: () => import("../pages/client/board/community/News.vue"),
        props: true,
    },
    {
        // 소통과 참여 - 소식지
        path: "/community/news/:_board",
        component: () => import("../pages/client/board/community/News.vue"),
        props: true,
    },
    {
        // 소통과 참여
        path: "/community/:_board",
        component: () => import("../pages/client/board/community/Board.vue"),
        props: true,
    },

    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/form/PrivacyPolicyPage.vue"),
        props: true,
    },













    // {
    //     path: "/common/kcp/cert/request",
    //     component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
    //     props: true,
    // },
    // {
    //     path: "/common/kcp/cert/response",
    //     component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
    //     props: true,
    // },
    // {
    //     // 응찰/낙찰 내역
    //     path: "/mypage/bids-:type",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BidsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 응찰/낙찰 내역
    //     path: "/mypage/bids-:type/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BidsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청내역 상세
    //     path: "/mypage/consignment-list/:_form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청내역 목록
    //     path: "/mypage/consignment-list",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 작품문의 상세
    //     path: "/mypage/private-list/:_form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PrivateViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 작품문의 목록
    //     path: "/mypage/private-list",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PrivateListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청
    //     path: "/mypage/consignment-form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentFormPage.vue"),
    //     props: true,
    // },
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },
    // {
    //     // 회원 정보 상세
    //     path: "/mypage/myinfo",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 정보 변경
    //     path: "/mypage/myinfo/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Search 페이지
    //     path: "/auction-search/",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/SearchPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Search 페이지
    //     path: "/auction-search/:keyword",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/SearchPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Hall 페이지
    //     path: "/auction-halls/:hall",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionHallPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Hall 페이지 - Group
    //     path: "/auction-halls/:hall/groups/:group",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionHallPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Work 페이지
    //     path: "/auction-works/:_work",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionWorkPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction - 지난경매
    //     path: "/auction-results",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionResultsPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction - 지난경매
    //     path: "/auction-results/:hall",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionResultsPage.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sales
    //     path: "/private-sales",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/private-sales/PrivateSalesPage.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sales
    //     path: "/private-sales/:tab",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/private-sales/PrivateSalesPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 위탁안내
    //     path: "/how-to/consignment",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToConsignmentPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 응찰안내
    //     path: "/how-to/bid",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToBidPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 응찰안내
    //     path: "/how-to/:tab",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToBidPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - FAQ
    //     path: "/center/faqs",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Collection
    //     path: "/collection",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/CollectionPage.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Museum Services
    //     path: "/museum-services",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/MuseumServicesPage.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Valuations
    //     path: "/valuations",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/ValuationsPage.vue"),
    //     props: true,
    // },
    // {
    //     // About - About & Mission
    //     path: "/about-mission",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/AboutMissionPage.vue"),
    //     props: true,
    // },
    // {
    //     // About - 공지사항
    //     path: "/center/notice",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // About - 공지사항
    //     path: "/center/notice/:_notification",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // About - 언론보도
    //     path: "/board/press",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Press.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // About - 위치안내
    //     path: "/location",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/LocationPage.vue"),
    //     props: true,
    // },
    // {
    //     // 캐터옥션 유튜브
    //     path: "/board/youtube",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Youtube.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 캐터옥션 리포트
    //     path: "/board/report",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Report.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // Forms & Archives
    //     path: "/board/forms",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Forms.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 경매약관
    //     path: "/auction-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/AuctionPolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 서비스이용약관
    //     path: "/service-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/ServicePolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 개인정보처리방침
    //     path: "/privacy-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 문의 및 제휴
    //     path: "/form/inquiry-partner",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청문의
    //     path: "/form/consignment-sale",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/ConsignmentSale.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sale 문의
    //     path: "/form/private-sale",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/PrivateSale.vue"),
    //     props: true,
    // },
    // {
    //     // 개인정보처리방침
    //     path: "/about/press",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/board/AuthorBoard.vue"),
    //     props: true,
    // },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 후원하기
        path: "/console/supports",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/support/SupportList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/supports/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/support/SupportView.vue"),
    },
    {
        // 관리자 - 후원하기
        path: "/console/supports/:_support",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/support/SupportView.vue"),
    },
    {
        // 관리자 - 캠페인
        path: "/console/campaign",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/campaign/CampaignList.vue"),
    },
    {
        // 관리자 - 캠페인 상세
        path: "/console/campaign/:_campaign",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/campaign/CampaignView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
















    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },

    {
        // 관리자 - 상품관리
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductList.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 생성
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 복사
        path: "/console/shop/products/copy",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 view
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 카테고리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/CategoryList.vue"),
        props: true,
    },

    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/logos",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/logos/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/logos/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf("console")) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
