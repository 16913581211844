<template>
    <client-page isMain class="app--main">
        <full-page ref="fullpage" :options="options" id="fullpage">
            <div class="fullpage-section fullpage-section--visual">
                <div class="visual white--text">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="swiper-slide__inner">
                                    <div class="visual__con">
                                        <div class="text-ani-wrap">
                                            <!-- <div class="text-ani mb-4 mb-lg-16"> -->
                                            <div class="mb-4 mb-lg-16">
                                                <h2 class="tit tit--lg font--serif line-height-1">[품] : 이웃을 안다</h2>
                                            </div>
                                            <!-- <div class="text-ani"> -->
                                            <div>
                                                <h4 class="tit tit--sm font-weight-regular line-height-14">소외된 이웃이 없는 평화로운 세상을 꿈꿉니다.</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide__bg d-none d-md-block" style="background-image: url(/images/main/visual.jpg)"></div>
                                    <div class="swiper-slide__bg d-block d-md-none" style="background-image: url(/images/main/visual-mo.jpg)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="visual-slide-control">
                            <div class="visual-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fullpage-section fullpage-section--link white--text">
                <v-row no-gutters class="h-100">
                    <v-col cols="12" md="6">
                        <router-link to="/support/regular" class="fullpage-inner link-item link-item--left">
                            <v-container>
                                <div class="link-item__inner" data-aos="fade-left">
                                    <h3 class="tit line-height-1 mb-6 mb-lg-16">정기후원</h3>
                                    <p class="page-text page-text--lg white--text line-height-15 mb-16 mb-lg-40">
                                        정기후원은소외된 이웃들에게 꾸준히 꿈과 희망을 전하는 방법입니다. <br class="d-none d-lg-block" />
                                        여러분의 작은 정성이 큰 변화를 만듭니다.
                                    </p>
                                    <v-btn outlined tile class="min-w-120px" color="white">
                                        바로가기
                                        <i class="icon icon-right-arrow ml-2 ml-lg-4"></i>
                                    </v-btn>
                                </div>
                            </v-container>
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="6">
                        <router-link to="/business/domestic/child" class="fullpage-inner link-item link-item--right">
                            <v-container>
                                <div class="link-item__inner" data-aos="fade-right">
                                    <h3 class="tit line-height-1 mb-6 mb-lg-16">사업안내</h3>
                                    <p class="page-text page-text--lg white--text line-height-15 mb-16 mb-lg-40">
                                        위드캔복지재단은 다양한 전문사회복지사업을 통해 소외되고 <br class="d-none d-lg-block" />
                                        어려운 이웃들에게 꿈과 희망을 심어주고 있습니다.
                                    </p>
                                    <v-btn outlined tile class="min-w-120px" color="white">
                                        바로가기
                                        <i class="icon icon-right-arrow ml-2 ml-lg-4"></i>
                                    </v-btn>
                                </div>
                            </v-container>
                        </router-link>
                    </v-col>
                </v-row>
            </div>

            <div class="fullpage-section fullpage-section--news">
                <div class="fullpage-inner">
                    <v-container>
                        <h3 class="tit line-height-1 mb-50 mb-lg-80 text-center" data-aos="fade-up">위드 캔 소식</h3>
                        <v-row align-md="center">
                            <v-col cols="12" md="6" lg="7" class="mb-20 mb-md-0" style="position: relative; z-index: 10">
                                <div class="w-100 pr-lg-56">
                                    <div class="main-board">
                                        <div class="main-board__head d-flex justify-space-between align-center mb-6 mb-md-16">
                                            <h4 class="tit tit--sm line-height-1">위드 캔 소식</h4>
                                            <router-link to="community/board?code=notice" class="text-btn">
                                                <span>자세히보기</span>
                                                <i class="icon icon-right-arrow-black ml-2 ml-lg-4"></i>
                                            </router-link>
                                        </div>
                                        <div class="main-board__contents">
                                            <!-- S::위드 캔 소식 -->
                                            <div v-for="(board, idx) in notice" :key="idx" class="main-board__row pa-8 py-md-20" @click="search(board._id)">
                                                <p class="page-text page-text--lg grey-1--text ellip mb-4 mb-lg-8">
                                                    {{ board.subject }}
                                                </p>
                                                <p class="font-size-12 font-size-lg-14 grey-9--text">
                                                    {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" lg="5">
                                <div class="main-thumb-board pa-20 pa-md-30 pa-lg-40">
                                    <div class="main-thumb-board__head white--text d-flex justify-space-between align-center mb-16">
                                        <h4 class="tit tit--sm line-height-1">진행 중인 캠페인</h4>
                                        <router-link to="/campaign/campaign?code=koCampaign" class="text-btn">
                                            <span>자세히보기</span>
                                            <i class="icon icon-right-arrow ml-2 ml-lg-4"></i>
                                        </router-link>
                                    </div>
                                    <div class="main-thumb-board__contents w-100">
                                        <!-- S::진행 중인 캠페인 -->
                                        <div v-for="(board, idx) in campaign" :key="idx" class="main-thumb-board__row w-100 d-flex">
                                            <div class="main-thumb-board__row__left">
                                                <div class="thumb">
                                                    <div class="thumb__inner" :style="`background-image:url(${board?.thumb});`"></div>
                                                </div>
                                            </div>
                                            <div class="main-thumb-board__row__right px-12 px-md-20">
                                                <p class="main-thumb-board__tit page-text grey-1--text mb-4 mb-lg-6">
                                                    {{ board.subject }}
                                                </p>
                                                <p class="font-size-12 font-size-lg-14 grey-9--text">
                                                    {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- E::진행 중인 캠페인 -->
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </div>
            <main-footer class="fullpage-section fp-auto-height"></main-footer>
        </full-page>
    </client-page>
</template>

<script>
import Swiper from "swiper/bundle";
import ClientPage from "@/pages/client/templates/ClientPage-FullPage.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
        MainFooter,
    },
    data() {
        return {
            options: {
                navigation: true,
                animateAnchor: false,
                anchors: ["Visual", "Link", "News", "Footer"],
                sectionSelector: ".fullpage-section",
                fitToSection: true,
                scrollingSpeed: 600,
                responsiveWidth: 1200,
                responsiveHeight: 800,
                easingcss3: "ease-in-out",
                licenseKey: "BE202675-E3234C07-967F61A1-57A110B1",
                onLeave: this.onLeave,
                onSlideLeave: this.onSlideLeave,
                afterLoad: this.afterLoad,
                afterSlideLoad: this.afterSlideLoad,
            },
            notice: null,
            campaign: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var noticeList = await api.v1.boards.gets({
                headers: {
                    limit: 4,
                },
                params: { code: "notice" },
            });
            this.notice = noticeList.boards;

            var campaignList = await api.v1.supports.donations.gets({
                headers: {
                    limit: 3,
                },
                params: { code: "koCampaign" },
            });
            this.campaign = campaignList.supports;
            console.log(campaignList, "dd");

            this.$nextTick(() => {
                this.Visual = new Swiper(".visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    // pagination: {
                    //     el: '.visual-pagination',
                    //     clickable: true,
                    // },
                });
            });
        },
        search(_board) {
            this.$router.push(`community/${_board}`);
        },
        // fullpage
        onLeave: function () {
            var fpActive = document.querySelector(".fp-section.active");
            var fpAos = fpActive.querySelectorAll(".aos-init");
            fpAos.forEach((item) => {
                item.classList.remove("aos-animate");
            });
        },
        onSlideLeave: function () {
            var fpActive = document.querySelector(".fp-section.active");
            var fpAos = fpActive.querySelectorAll(".aos-init");
            fpAos.forEach((item) => {
                item.classList.remove("aos-animate");
            });
        },
        afterLoad: function (origin, destination, direction) {
            var fpActive = document.querySelector(".fp-section.active");
            var fpAos = fpActive.querySelectorAll(".aos-init");
            fpAos.forEach((item) => {
                item.classList.add("aos-animate");
            });
        },
        afterSlideLoad: function () {
            var fpActive = document.querySelector(".fp-section.active");
            var fpAos = fpActive.querySelectorAll(".aos-init");
            fpAos.forEach((item) => {
                item.classList.add("aos-animate");
            });
        },
    },
};
</script>

<style scoped>
.visual {
    position: relative;
}
.visual .swiper-slide {
    width: 100%;
    height: 100vh;
}
.swiper-slide__inner {
    width: 100%;
    padding-top: calc(var(--header-head) + var(--header-body));
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.visual__con {
    width: 100%;
    padding: 0 12px;
    max-width: calc(var(--container) + 24px);
    position: relative;
    text-align: center;
    margin-bottom: 350px;
    z-index: 1;
}
.swiper-slide__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 0;
}

.text-ani-wrap {
    margin-top: -4px;
}
.text-ani {
    padding-top: 4px;
}

.visual-slide-control {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: var(--header-body) 12px 30px;
    max-width: calc(var(--container) + 24px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    display: none;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .visual__con {
        text-align: left;
        margin-bottom: inherit;
    }
    .visual-slide-control {
        padding: var(--header-body) 12px 0;
        align-items: center;
        justify-content: flex-start;
    }
    .text-ani-wrap {
        margin-bottom: 42px;
    }
    .visual-pagination {
        margin-top: 130px;
    }
}
@media (min-width: 1024px) {
    .text-ani-wrap {
        margin-bottom: 52px;
    }
    .visual-pagination {
        margin-top: 174px;
    }
}
@media (min-width: 1200px) {
}
</style>
