<template>
    <footer class="footer">
        <v-container>
            <v-row class="flex-lg-row-reverse">
                <v-col cols="12" lg="4" class="mb-20 mb-lg-0">
                    <v-img src="/images/ci.svg" class="footer__logo ml-lg-auto"></v-img>
                </v-col>
                <v-col cols="12" lg="8">
                    <p class="font-weight-medium grey-1--text mb-12 mb-lg-16">사회복지법인 위드 캔 복지재단</p>
                    <p class="d-md-inline-block mr-md-10 mr-lg-16"><span class="grey-6--text">사업자등록번호</span> 112-82-03363</p>
                    <p class="d-md-inline-block mr-md-10 mr-lg-16 mt-4 mt-md-0"><span class="grey-6--text">전화</span> 02-2057-2296</p>
                    <p class="d-md-inline-block mt-4 mt-md-0"><span class="grey-6--text">팩스</span> 02-2057-2295</p>
                    <p class="mt-4 mt-lg-8"><span class="grey-6--text">주소</span> 서울특별시 성북구 화랑로11길 26 갑을명가 2층 215</p>
                    <v-btn color="grey-f5" class="mt-4 mt-lg-8" to="/privacy-policy">
                        <span class="page-text page-text--sm primary--text">개인정보처리방침</span>
                    </v-btn>
                    <p class="mt-12 mt-lg-16">Copyright 2023. 사회복지법인 위드 캔 복지재단. All Rights Reserved.</p>
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>
// import UScrollTop from "@/components/dumb/u-scroll-top.vue";

export default {
    components: {
        // UScrollTop,
    },
};
</script>

<style scoped>
.footer {
    border-top: 1px solid #f5f5f5;
    padding: 40px 0;
    font-size: 1.4rem;
    color: #999;
}
.footer__logo {
    width: 208px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .footer {
        padding: 48px 0;
    }
    .footer__logo {
        width: 310px;
    }
}
@media (min-width: 1200px) {
}
</style>
