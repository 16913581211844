var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    staticClass: "gnb__link donation-link",
    attrs: {
      "href": "https://secure.donus.org/withcan/pay",
      "target": "_blank"
    }
  }, [_vm._v("후원하기")]), _c('a', {
    staticClass: "gnb__link d-xl-none",
    attrs: {
      "href": "https://secure.donus.org/withcan/mypage",
      "target": "_blank"
    }
  }, [_vm._v("후원하기 마이페이지")])]);

}]

export { render, staticRenderFns }