var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main",
    attrs: {
      "isMain": ""
    }
  }, [_c('full-page', {
    ref: "fullpage",
    attrs: {
      "options": _vm.options,
      "id": "fullpage"
    }
  }, [_c('div', {
    staticClass: "fullpage-section fullpage-section--visual"
  }, [_c('div', {
    staticClass: "visual white--text"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "swiper-slide__inner"
  }, [_c('div', {
    staticClass: "visual__con"
  }, [_c('div', {
    staticClass: "text-ani-wrap"
  }, [_c('div', {
    staticClass: "mb-4 mb-lg-16"
  }, [_c('h2', {
    staticClass: "tit tit--lg font--serif line-height-1"
  }, [_vm._v("[품] : 이웃을 안다")])]), _c('div', [_c('h4', {
    staticClass: "tit tit--sm font-weight-regular line-height-14"
  }, [_vm._v("소외된 이웃이 없는 평화로운 세상을 꿈꿉니다.")])])])]), _c('div', {
    staticClass: "swiper-slide__bg d-none d-md-block",
    staticStyle: {
      "background-image": "url(/images/main/visual.jpg)"
    }
  }), _c('div', {
    staticClass: "swiper-slide__bg d-block d-md-none",
    staticStyle: {
      "background-image": "url(/images/main/visual-mo.jpg)"
    }
  })])])]), _c('div', {
    staticClass: "visual-slide-control"
  }, [_c('div', {
    staticClass: "visual-pagination"
  })])])])]), _c('div', {
    staticClass: "fullpage-section fullpage-section--link white--text"
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('router-link', {
    staticClass: "fullpage-inner link-item link-item--left",
    attrs: {
      "to": "/support/regular"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "link-item__inner",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h3', {
    staticClass: "tit line-height-1 mb-6 mb-lg-16"
  }, [_vm._v("정기후원")]), _c('p', {
    staticClass: "page-text page-text--lg white--text line-height-15 mb-16 mb-lg-40"
  }, [_vm._v(" 정기후원은소외된 이웃들에게 꾸준히 꿈과 희망을 전하는 방법입니다. "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 여러분의 작은 정성이 큰 변화를 만듭니다. ")]), _c('v-btn', {
    staticClass: "min-w-120px",
    attrs: {
      "outlined": "",
      "tile": "",
      "color": "white"
    }
  }, [_vm._v(" 바로가기 "), _c('i', {
    staticClass: "icon icon-right-arrow ml-2 ml-lg-4"
  })])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('router-link', {
    staticClass: "fullpage-inner link-item link-item--right",
    attrs: {
      "to": "/business/domestic/child"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "link-item__inner",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_c('h3', {
    staticClass: "tit line-height-1 mb-6 mb-lg-16"
  }, [_vm._v("사업안내")]), _c('p', {
    staticClass: "page-text page-text--lg white--text line-height-15 mb-16 mb-lg-40"
  }, [_vm._v(" 위드캔복지재단은 다양한 전문사회복지사업을 통해 소외되고 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 어려운 이웃들에게 꿈과 희망을 심어주고 있습니다. ")]), _c('v-btn', {
    staticClass: "min-w-120px",
    attrs: {
      "outlined": "",
      "tile": "",
      "color": "white"
    }
  }, [_vm._v(" 바로가기 "), _c('i', {
    staticClass: "icon icon-right-arrow ml-2 ml-lg-4"
  })])], 1)])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "fullpage-section fullpage-section--news"
  }, [_c('div', {
    staticClass: "fullpage-inner"
  }, [_c('v-container', [_c('h3', {
    staticClass: "tit line-height-1 mb-50 mb-lg-80 text-center",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("위드 캔 소식")]), _c('v-row', {
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-20 mb-md-0",
    staticStyle: {
      "position": "relative",
      "z-index": "10"
    },
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "7"
    }
  }, [_c('div', {
    staticClass: "w-100 pr-lg-56"
  }, [_c('div', {
    staticClass: "main-board"
  }, [_c('div', {
    staticClass: "main-board__head d-flex justify-space-between align-center mb-6 mb-md-16"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v("위드 캔 소식")]), _c('router-link', {
    staticClass: "text-btn",
    attrs: {
      "to": "community/board?code=notice"
    }
  }, [_c('span', [_vm._v("자세히보기")]), _c('i', {
    staticClass: "icon icon-right-arrow-black ml-2 ml-lg-4"
  })])], 1), _c('div', {
    staticClass: "main-board__contents"
  }, _vm._l(_vm.notice, function (board, idx) {
    return _c('div', {
      key: idx,
      staticClass: "main-board__row pa-8 py-md-20",
      on: {
        "click": function ($event) {
          return _vm.search(board._id);
        }
      }
    }, [_c('p', {
      staticClass: "page-text page-text--lg grey-1--text ellip mb-4 mb-lg-8"
    }, [_vm._v(" " + _vm._s(board.subject) + " ")]), _c('p', {
      staticClass: "font-size-12 font-size-lg-14 grey-9--text"
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])]);
  }), 0)])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "main-thumb-board pa-20 pa-md-30 pa-lg-40"
  }, [_c('div', {
    staticClass: "main-thumb-board__head white--text d-flex justify-space-between align-center mb-16"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v("진행 중인 캠페인")]), _c('router-link', {
    staticClass: "text-btn",
    attrs: {
      "to": "/campaign/campaign?code=koCampaign"
    }
  }, [_c('span', [_vm._v("자세히보기")]), _c('i', {
    staticClass: "icon icon-right-arrow ml-2 ml-lg-4"
  })])], 1), _c('div', {
    staticClass: "main-thumb-board__contents w-100"
  }, _vm._l(_vm.campaign, function (board, idx) {
    return _c('div', {
      key: idx,
      staticClass: "main-thumb-board__row w-100 d-flex"
    }, [_c('div', {
      staticClass: "main-thumb-board__row__left"
    }, [_c('div', {
      staticClass: "thumb"
    }, [_c('div', {
      staticClass: "thumb__inner",
      style: `background-image:url(${board === null || board === void 0 ? void 0 : board.thumb});`
    })])]), _c('div', {
      staticClass: "main-thumb-board__row__right px-12 px-md-20"
    }, [_c('p', {
      staticClass: "main-thumb-board__tit page-text grey-1--text mb-4 mb-lg-6"
    }, [_vm._v(" " + _vm._s(board.subject) + " ")]), _c('p', {
      staticClass: "font-size-12 font-size-lg-14 grey-9--text"
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])])]);
  }), 0)])])], 1)], 1)], 1)]), _c('main-footer', {
    staticClass: "fullpage-section fp-auto-height"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }