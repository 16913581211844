import axios from "@/plugins/axios";

let url = "/api/console/supports/${_support}/files";

export default {
    gets(data) {
        return axios.get(url.interpolate(data), data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url.interpolate(data)}/${data._id}`).then((result) => result.data);
    },
    post({ _support, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_support) formData.append("_support", _support);
        if (index !== undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(`${url.interpolate({ _support })}`, formData, { headers }).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url.interpolate(data)}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url.interpolate(data)}/${data._id}`).then((result) => result.data);
    },
};
