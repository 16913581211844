var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "flex-lg-row-reverse"
  }, [_c('v-col', {
    staticClass: "mb-20 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('v-img', {
    staticClass: "footer__logo ml-lg-auto",
    attrs: {
      "src": "/images/ci.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('p', {
    staticClass: "font-weight-medium grey-1--text mb-12 mb-lg-16"
  }, [_vm._v("사회복지법인 위드 캔 복지재단")]), _c('p', {
    staticClass: "d-md-inline-block mr-md-10 mr-lg-16"
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("사업자등록번호")]), _vm._v(" 112-82-03363")]), _c('p', {
    staticClass: "d-md-inline-block mr-md-10 mr-lg-16 mt-4 mt-md-0"
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("전화")]), _vm._v(" 02-2057-2296")]), _c('p', {
    staticClass: "d-md-inline-block mt-4 mt-md-0"
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("팩스")]), _vm._v(" 02-2057-2295")]), _c('p', {
    staticClass: "mt-4 mt-lg-8"
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("주소")]), _vm._v(" 서울특별시 성북구 화랑로11길 26 갑을명가 2층 215")]), _c('v-btn', {
    staticClass: "mt-4 mt-lg-8",
    attrs: {
      "color": "grey-f5",
      "to": "/privacy-policy"
    }
  }, [_c('span', {
    staticClass: "page-text page-text--sm primary--text"
  }, [_vm._v("개인정보처리방침")])]), _c('p', {
    staticClass: "mt-12 mt-lg-16"
  }, [_vm._v("Copyright 2023. 사회복지법인 위드 캔 복지재단. All Rights Reserved.")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }