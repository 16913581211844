<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header></main-header>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main>
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <popup-layer v-if="isMain"/>
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import PopupLayer from '@/components/client/popup/popup-layer.vue';
export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        PopupLayer
    },
    mounted() {
        window.addEventListener("copy", this.preventCopy);
        window.addEventListener("contextmenu", this.preventCopy);
        window.addEventListener("selectstart", this.preventDefault);
        window.addEventListener("dragstart", this.preventDefault);
        this.init();
    },
    destroyed() {
        window.removeEventListener("copy", this.preventCopy);
        window.removeEventListener("contextmenu", this.preventCopy);
        window.removeEventListener("selectstart", this.preventDefault);
        window.removeEventListener("dragstart", this.preventDefault);
    },
    methods: {
		init : function() {
            setTimeout(() => {
                AOS.init({
                    once: false,
                    offset: 240,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-in-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
    },
};
</script>
